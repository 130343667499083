import { Create } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { IDeliverable, IProduct } from 'interfaces/product'
import { DeliverableForm } from './Form'

export const DeliverableCreate: React.FC = () => {
  const useFormProps = useForm<IDeliverable>({
    redirect: 'edit',
  })

  return (
    <Create footerButtons={() => <></>}>
      <DeliverableForm useFormProps={useFormProps} />
    </Create>
  )
}
