import React, { useState } from 'react'
import { DateField, RefreshButton, Show } from '@refinedev/antd'
import {
  Alert,
  Button,
  Descriptions,
  Divider,
  Input,
  Modal,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd'
import { BaseKey, useCreate, useOne, useShow } from '@refinedev/core'
import { CheckAccess } from 'components/checkAccess'
import { PriceField } from 'components/field'
import { REJECTED } from 'consts'
import { IPayoutDetailed } from 'interfaces/payout'
import ReactJson from 'react-json-view'
import { PAYOUT_URL } from 'urls'
import {
  convertStatusName,
  getPayoutStatusColor,
  getUserName,
} from 'utils/common'
import TDS_Amount from './TDS_Amount'
import AddTdsModal from './AddTdsModal'

const { Title, Text, Link } = Typography

export const PayoutShow: React.FC = () => {
  const { queryResult, showId } = useShow<IPayoutDetailed>()
  const { data, isLoading } = queryResult
  const record = data?.data

  const { refetch: refetchRawDetails } = useOne({
    resource: 'package_tool/payouts',
    id: `${showId}/refetch`,
    queryOptions: {
      enabled: false,
      onSuccess: () => {
        queryResult.refetch()
      },
    },
  })

  const { mutate: payoutPost } = useCreate()
  const [isHoldModalVisible, setIsHoldModalVisible] = useState(false)
  const [holdReason, setHoldReason] = useState('')
  const [currentRuleId, setCurrentRuleId] = useState<BaseKey | null>(null)

  const handleStatusChange = (status: any, id: BaseKey) => {
    if (status === 'hold') {
      setCurrentRuleId(id)
      setIsHoldModalVisible(true)
      return
    }

    payoutPost(
      {
        resource: `${PAYOUT_URL}/${showId}/${status}`,
        values: {
          effected_rule_id: id,
          ...(status === 'hold' ? { hold_reason: holdReason } : {}),
        },
      },
      { onSettled: () => queryResult.refetch() },
    )
  }

  const handleHoldConfirm = () => {
    if (currentRuleId) {
      payoutPost(
        {
          resource: `${PAYOUT_URL}/${showId}/hold`,
          values: { effected_rule_id: currentRuleId, hold_reason: holdReason },
        },
        { onSettled: () => queryResult.refetch() },
      )
      setIsHoldModalVisible(false)
      setHoldReason('')
    }
  }

  return (
    <CheckAccess resource="access_payouts">
      <Show isLoading={isLoading}>
        {!!queryResult.data?.data.effected_rules.length && (
          <Space direction="vertical" style={{ width: '100%' }}>
            {queryResult.data?.data.effected_rules.map((rule) => (
              <div key={rule.id}>
                <Alert
                  message={
                    !rule.approved && !rule.rejected && !rule.on_hold
                      ? 'Pending Approval'
                      : rule.approved
                      ? 'Approved'
                      : rule.rejected
                      ? 'Rejected'
                      : 'On Hold'
                  }
                  description={
                    <>
                      <>
                        {!!rule.user && (
                          <>
                            By {getUserName(rule.user)} at{' '}
                            <DateField value={rule.updated_at} format="LLL" />
                            <br />
                          </>
                        )}
                      </>
                      <>
                        {rule.rule.for_under_collected ? (
                          <>
                            Payment exceeds collection by{' '}
                            <PriceField value={rule.excess_amount as number} />
                          </>
                        ) : (
                          'Over the allowed limit'
                        )}
                      </>
                      <>
                        {' '}
                        <br />
                        Payout Rule Type: {rule.rule?.rule_for}
                      </>
                      {rule.on_hold && !rule.approved && !rule.rejected && (
                        <>
                          <br />
                          <Text strong>Hold Reason:</Text> {rule.hold_reason}
                        </>
                      )}
                    </>
                  }
                  type={
                    !rule.approved && !rule.rejected && !rule.on_hold
                      ? 'warning'
                      : rule.approved
                      ? 'success'
                      : rule.rejected
                      ? 'error'
                      : 'info'
                  }
                  showIcon
                  action={
                    !rule.approved &&
                    !rule.rejected && (
                      <Space size="large">
                        <Button
                          type="primary"
                          onClick={() =>
                            handleStatusChange('approve', rule.id)
                          }>
                          Approve
                        </Button>
                        <Button
                          type="primary"
                          danger
                          onClick={() => handleStatusChange('reject', rule.id)}>
                          Reject
                        </Button>
                        <Button
                          type="default"
                          onClick={() => handleStatusChange('hold', rule.id)}>
                          Hold
                        </Button>
                      </Space>
                    )
                  }
                />
              </div>
            ))}
            <Divider />
          </Space>
        )}

        <Space direction="horizontal">
          <Space direction="vertical">
            <Descriptions column={1}>
              <Descriptions.Item label={'Project ID'}>
                {record?.event ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Package'}>
                <Link
                  href={`/package_tool/shortlist_packages/edit/${record?.package}`}
                  target="_blank">
                  {record?.package ?? ''}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label={'Transfer ID'}>
                {record?.id ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'UTR Number'}>
                {record?.raw_response?.utr ?? ''}
              </Descriptions.Item>
              <Descriptions.Item label={'Event Dates'}>
                {record?.event_dates?.map((value, idx) => (
                  <span key={idx}>
                    <DateField value={value} format="LLL" />{' '}
                  </span>
                ))}
              </Descriptions.Item>
              <Descriptions.Item label={'Remarks'}>
                {record?.remarks ?? ''}
              </Descriptions.Item>
            </Descriptions>
          </Space>
          <div>
            <Tag color={getPayoutStatusColor(record?.status ?? '')}>
              {convertStatusName(record?.status ?? '')}
            </Tag>
            <br />
            <PriceField value={record?.amount ?? 0} />
          </div>
        </Space>
        <Divider />
        <div className="row">
          <Space direction="vertical">
            {record?.basic_gst_summary && (
              <Descriptions title="GST Summary">
                <Descriptions.Item label="Base Total" span={20}>
                  {record.basic_gst_summary.base_total}
                </Descriptions.Item>
                <Descriptions.Item label="GST Total" span={20}>
                  {record.basic_gst_summary.gst_total}
                </Descriptions.Item>

                <Descriptions.Item label="Total for Vendor" span={20}>
                  {record.basic_gst_summary.total_for_vendor}
                </Descriptions.Item>
                <Descriptions.Item label="Payout Amount" span={20}>
                  <PriceField value={record.amount ?? 0} />
                </Descriptions.Item>
                <Descriptions.Item label="tds Total" span={20}>
                  {record.basic_gst_summary.total_tds_amount}
                </Descriptions.Item>
                <Descriptions.Item label="Total Payment After TDS" span={20}>
                  <PriceField value={record.amount_to_pay} />
                </Descriptions.Item>
              </Descriptions>
            )}
            {record?.basic_gst_summary && (
              <div>
                <Table
                  columns={[
                    {
                      title: 'TDS Section',
                      dataIndex: 'tds_section',
                      key: 'tds_section',
                    },
                    {
                      title: 'Rate',
                      dataIndex: 'rate',
                      key: 'rate',
                    },
                    {
                      title: 'Taxable Amount',
                      dataIndex: 'taxable_amount',
                      key: 'taxable_amount',
                    },
                    {
                      title: 'TDS Amount',
                      dataIndex: 'tds_amount',
                      key: 'tds_amount',
                    },
                  ]}
                  dataSource={record.basic_gst_summary.tds_summary}
                  rowKey="tds_section"
                  pagination={false} // Disable pagination if you don't need it
                  bordered
                />
              </div>
            )}
            <Text></Text>
          </Space>
        </div>
        <Space>
          {showId && record?.purchase_order && (
            <AddTdsModal
              payout={showId}
              purchase_order={record?.purchase_order.id ?? 0}
              tds_records={record?.tds_records ?? []}
              refetch={refetchRawDetails}
            />
          )}
        </Space>

        <Divider />
        <div className="row">
          <Space direction="vertical">
            <Title level={4}>Vendor Details</Title>
            <Text>
              Name:{' '}
              {record?.availability !== null && record?.availability === 'Venue'
                ? record?.venue?.name
                : record?.vendor?.name}
            </Text>
            <Text>PAN: {record?.vendor?.pan_number}</Text>
          </Space>
        </div>
        <Divider />
        <Space direction="vertical">
          <Title level={4}>Date and Time</Title>
          <Text>
            Created At: <DateField value={record?.created_at} format="LLL" />
          </Text>
          <Text>
            Updated At: <DateField value={record?.updated_at} format="LLL" />
          </Text>
          {record?.status_change_at && (
            <>
              <Text>
                Approved/Rejected By:{' '}
                <>
                  {record?.status_change_by.first_name}{' '}
                  {record?.status_change_by.last_name}
                </>
              </Text>
              <Text>
                Approved/Rejected At:{' '}
                <DateField value={record?.status_change_at} format="LLL" />
              </Text>
            </>
          )}
        </Space>
        <Divider />
        <Space>
          <Title level={4}>Raw Details</Title>{' '}
          <RefreshButton
            size="small"
            onClick={() => {
              refetchRawDetails()
            }}
            disabled={
              queryResult.data?.data.approval_required ||
              queryResult.data?.data.status === REJECTED
            }
          />
        </Space>
        <ReactJson
          src={record?.raw_response ?? {}}
          collapsed
          displayDataTypes={false}
        />

        <Modal
          visible={isHoldModalVisible}
          onCancel={() => {
            setIsHoldModalVisible(false)
            setHoldReason('')
          }}
          onOk={handleHoldConfirm}
          title="Hold Rule">
          <Input.TextArea
            rows={4}
            value={holdReason}
            onChange={(e) => setHoldReason(e.target.value)}
            placeholder="Enter reason for holding this rule"
          />
        </Modal>
      </Show>
    </CheckAccess>
  )
}
