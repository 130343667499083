import { Create } from '@refinedev/antd'
import { useForm } from 'components/hooks/useForm'
import { IProduct } from 'interfaces/product'
import { ProductForm } from './Form'

export const ProductCreate: React.FC = () => {
  const useFormProps = useForm<IProduct>({
    redirect: 'edit',
  })

  return (
    <Create saveButtonProps={useFormProps.saveButtonProps}>
      <ProductForm useFormProps={useFormProps} />
    </Create>
  )
}
