import { Edit, ListButton, RefreshButton, SaveButton } from '@refinedev/antd'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Button, Card, Form, Image, Modal, Spin, Typography } from 'antd'
import { useCan, useCreate, useModal, useRouterContext } from '@refinedev/core'
import { LogButton } from 'components/button'
import { useForm } from 'components/hooks/useForm'
import parse from 'html-react-parser'
import { IProduct } from 'interfaces/product'
import { useState } from 'react'
import ReactQuill from 'react-quill'
import { ProductForm } from './Form'

export const ProductEdit: React.FC = (props) => {
  const useFormProps = useForm<IProduct>({ redirect: false })
  const [showImage, setShowImage] = useState(false)

  const { mutate: postProduct } = useCreate()

  const { useParams } = useRouterContext()
  const { id } = useParams<any>()

  const { data: writeCatalog } = useCan({
    resource: 'write_catalog',
    action: '',
  })

  const { visible, show, close } = useModal()

  const { form } = useForm()

  const isDeleted = useFormProps.queryResult?.data?.data.is_deleted

  return (
    <Edit
      footerButtons={() => (
        <>
          {writeCatalog?.can && isDeleted ? (
            <Button onClick={show} icon={<Icons.RedoOutlined />} type="primary">
              Restore
            </Button>
          ) : (
            <Button onClick={show} danger icon={<Icons.DeleteOutlined />}>
              Delete
            </Button>
          )}
          {!useFormProps.queryResult?.data?.data.is_deleted && (
            <SaveButton {...useFormProps.saveButtonProps} />
          )}
        </>
      )}
      headerProps={{
        ghost: false,
        title: 'Edit Product',
        extra: [
          <LogButton key={'log'} />,
          <Button
            key="show image"
            onClick={() => setShowImage(!showImage)}
            icon={<Icons.PictureOutlined />}>
            Hide/Show Image
          </Button>,
          <ListButton key="list" />,
          <RefreshButton key="refresh" />,
        ],
      }}>
      {useFormProps.formLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Spin />
        </div>
      ) : (
        <>
          {showImage && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Image
                src={useFormProps.queryResult?.data?.data.images[0].image}
                width={200}
                height={200}
              />
            </div>
          )}
          {isDeleted && (
            <Card
              title={<Typography.Text type="danger">Deleted</Typography.Text>}
              style={{ borderColor: 'red' }}
              headStyle={{ borderColor: 'red' }}>
              <Typography.Paragraph>
                {parse(
                  useFormProps.queryResult?.data?.data.delete_reason ?? '',
                )}
              </Typography.Paragraph>
            </Card>
          )}
          <ProductForm useFormProps={useFormProps} isEdit />
          <Modal
            visible={visible}
            title={isDeleted ? 'Restore' : 'Delete'}
            onCancel={close}
            onOk={() => {
              form.submit()
              close()
            }}
            okText={isDeleted ? 'Restore' : 'Delete'}>
            <Form
              form={form}
              onFinish={(values) => {
                postProduct(
                  {
                    resource: `cms/product/${id}/${
                      isDeleted ? 'restore' : 'delete'
                    }`,
                    values,
                    successNotification: {
                      message: 'Operation Successful',
                      type: 'success',
                      description: 'Success',
                    },
                  },
                  { onSuccess: () => useFormProps.queryResult?.refetch() },
                )
              }}>
              <Form.Item
                name="delete_reason"
                label="Reason"
                initialValue={''}
                rules={[{ required: true, message: 'This is required' }]}>
                <ReactQuill theme="snow" />
              </Form.Item>
            </Form>
          </Modal>
        </>
      )}
    </Edit>
  )
}
