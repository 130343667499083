import {
  List as AntdList,
  Button,
  Collapse,
  Descriptions,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
} from 'antd'
import { useCan, useDelete, useList, useOne } from '@refinedev/core'
import { useDrawerForm } from '@refinedev/antd'
import {
  INVENTORY_URL,
  INVENTORY_DELIVERABLES_URL,
  DELIVERABLES_URL,
} from 'urls'
import parse from 'html-react-parser'
import { IInventory } from 'interfaces/product'
import { IDeliverable, IInventoryDeliverable } from 'interfaces/product'
import { IVendor } from 'interfaces/vendor'
import { IRegion } from 'interfaces/region'
import { useState, useEffect } from 'react'

const { Panel } = Collapse

export const InventoryCard: React.FC<{
  item: IInventory
  showEdit: CallableFunction
  refetch: CallableFunction
}> = ({ item, showEdit, refetch }) => {
  const { mutate: destroy } = useDelete()

  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const [selectedDeliverableId, setSelectedDeliverableId] = useState<
    number | null
  >(null)

  const { data: addInventoryDeliverablePerm } = useCan({
    resource: 'add_inventorydeliverables',
    action: '',
  })

  const { data: updateInventoryDeliverablePerm } = useCan({
    resource: 'add_inventorydeliverables',
    action: '',
  })

  const { data: deleteInventoryDeliverablePerm } = useCan({
    resource: 'delete_inventorydeliverables',
    action: '',
  })

  const { data: deliverablesData, refetch: refetchDeliverables } =
    useList<IInventoryDeliverable>({
      resource: INVENTORY_DELIVERABLES_URL,
      pagination: { mode: 'off' },
      filters: [
        {
          field: 'inventory',
          operator: 'eq',
          value: item.id,
        },
        {
          field: 'expand',
          operator: 'eq',
          value: 'deliverable',
        },
      ],
      queryOptions: {
        enabled: isPanelOpen,
      },
    })

  const { data: allDeliverablesData } = useList<IDeliverable>({
    resource: DELIVERABLES_URL,
    pagination: { mode: 'off' },
    filters: [
      { field: 'is_deleted', operator: 'eq', value: false }, // Only fetch active deliverables
    ],
  })

  // Fetch selected deliverable details
  const { data: selectedDeliverable, refetch: refetchSelectedDeliverable } =
    useOne<IDeliverable>({
      resource: DELIVERABLES_URL,
      id: selectedDeliverableId || undefined, // Fetch only if an ID is set
      queryOptions: {
        enabled: !!selectedDeliverableId, // Disable fetching if no ID
      },
    })

  // Drawer form for adding/editing LineItemDeliverables
  const {
    drawerProps: deliverableCreateDrawerProps,
    formProps: deliverableCreateFormProps,
    show: showCreateDeliverableDrawer,
    formLoading: deliverableCreateFormLoading,
  } = useDrawerForm<IInventoryDeliverable>({
    resource: INVENTORY_DELIVERABLES_URL,
    action: 'create',
    redirect: false,
  })

  const {
    drawerProps: deliverableEditDrawerProps,
    formProps: deliverableEditFormProps,
    show: showEditDeliverableDrawer,
    id: deliverableId,
    formLoading: deliverableEditFormLoading,
  } = useDrawerForm<IInventoryDeliverable>({
    resource: INVENTORY_DELIVERABLES_URL,
    action: 'edit',
    redirect: false,
  })

  const editOrCreate = (edit: any, create: any) =>
    deliverableId ? edit : create

  useEffect(() => {
    if (selectedDeliverableId && !deliverableId) {
      // Pre-fill fields for new deliverable if selected
      deliverableCreateFormProps.form.setFieldsValue({
        quantity: selectedDeliverable?.data?.default_qty || 0,
        description: selectedDeliverable?.data?.description || '',
      })
    }
  }, [selectedDeliverableId, selectedDeliverable, deliverableId])

  const handleDeliverableChange = (value: number) => {
    setSelectedDeliverableId(value)
  }

  return (
    <>
      <AntdList.Item
        key={item.id}
        actions={[
          <Button
            size="small"
            type="text"
            key="edit"
            onClick={() => showEdit(item.id)}>
            Edit
          </Button>,
          <Button
            size="small"
            danger
            type="text"
            key="delete"
            onClick={() => destroy({ resource: INVENTORY_URL, id: item.id })}>
            Delete
          </Button>,
        ]}>
        <AntdList.Item.Meta
          title={(item.region as IRegion).name}
          description={
            <Descriptions>
              <Descriptions.Item label="Vendor">
                {(item.vendor as IVendor).name}
              </Descriptions.Item>
              <Descriptions.Item label="Price">{item.price}</Descriptions.Item>
            </Descriptions>
          }
        />
      </AntdList.Item>

      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Description" key="1">
          <p>{parse(item.description ?? '')}</p>
        </Collapse.Panel>
      </Collapse>

      <Collapse
        onChange={(keys) => {
          if (keys.includes('1')) {
            setIsPanelOpen(true)
          } else {
            setIsPanelOpen(false)
          }
        }}>
        <Panel header="Deliverables" key="1">
          {deliverablesData?.data.length ? (
            <AntdList
              dataSource={deliverablesData?.data}
              renderItem={(inventory_deliverable: IInventoryDeliverable) => (
                <AntdList.Item
                  key={inventory_deliverable.id}
                  actions={[
                    <Button
                      size="small"
                      type="text"
                      key="edit"
                      disabled
                      onClick={() =>
                        showEditDeliverableDrawer(inventory_deliverable.id)
                      }>
                      Edit
                    </Button>,
                    <Button
                      size="small"
                      danger
                      type="text"
                      key="delete"
                      disabled={!deleteInventoryDeliverablePerm?.can}
                      onClick={() =>
                        destroy({
                          resource: INVENTORY_DELIVERABLES_URL,
                          id: inventory_deliverable.id,
                        })
                      }>
                      Delete
                    </Button>,
                  ]}>
                  <AntdList.Item.Meta
                    title={`${
                      (inventory_deliverable.deliverable as IDeliverable)?.name
                    } (${inventory_deliverable.quantity} ${
                      (inventory_deliverable.deliverable as IDeliverable)
                        ?.uom || ''
                    })`}
                    description={parse(
                      (inventory_deliverable.deliverable as IDeliverable)
                        ?.description ?? 'No Description',
                    )}
                  />
                </AntdList.Item>
              )}
            />
          ) : (
            <p>No deliverables found.</p>
          )}
          <Button
            disabled={!addInventoryDeliverablePerm?.can}
            type="primary"
            onClick={() => showCreateDeliverableDrawer()}>
            Add Deliverable
          </Button>
        </Panel>
      </Collapse>

      {/* Drawer form for adding/editing deliverables */}
      <Drawer
        {...editOrCreate(
          deliverableEditDrawerProps,
          deliverableCreateDrawerProps,
        )}
        title={deliverableId ? 'Edit Deliverable' : 'Add Deliverable'}
        destroyOnClose>
        {deliverableCreateFormLoading || deliverableEditFormLoading ? (
          <Spin />
        ) : (
          <Form
            {...editOrCreate(
              deliverableEditFormProps,
              deliverableCreateFormProps,
            )}
            id={deliverableId}
            layout="vertical"
            preserve={false}
            onFinish={(values) => {
              editOrCreate(
                deliverableEditFormProps,
                deliverableCreateFormProps,
              ).onFinish({
                ...values,
                inventory: item!.id,
              })
            }}>
            <Form.Item
              label="Deliverable"
              name="deliverable"
              rules={[
                { required: true, message: 'Please select a deliverable' },
              ]}>
              <Select
                placeholder="Select a deliverable"
                options={allDeliverablesData?.data.map((d) => ({
                  label: d.name + ' ' + d.uom + ' - ' + d.description,
                  value: d.id,
                }))}
                onChange={handleDeliverableChange}
              />
            </Form.Item>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ required: true, message: 'Please enter a quantity' }]}>
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={() =>
                  editOrCreate(
                    deliverableEditFormProps,
                    deliverableCreateFormProps,
                  ).form.submit()
                }>
                Save
              </Button>
            </Form.Item>
          </Form>
        )}
      </Drawer>
    </>
  )
}
