import * as Icons from '@ant-design/icons'

import {
  Button,
  Card,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd'
import { useCan, useDelete, useOne, useUpdate } from '@refinedev/core'
import { PriceField } from 'components/field'
import {
  IPayment,
  IPaymentDetailed,
  Service,
  ServicePaymentSplit,
} from 'interfaces/payment'
import ReactJson from 'react-json-view'
import { useEffect, useState } from 'react'
import { PAYMENT_URL, PROJECT_URL, SERVICE_URL } from 'urls'
import { DatePicker } from 'components/input'
import { SelectWithDefault } from 'components/input/SelectWithDefault'
import { useSelect } from '@refinedev/antd'
import { IEvent } from 'interfaces/customer'

function PaymentStatus(props: any) {
  const Status: React.FC<{ color: string; status: string }> = ({
    color,
    status,
  }) => {
    return <Typography.Text style={{ color: color }}>{status}</Typography.Text>
  }

  let statusColor
  let status

  switch (props.status) {
    case 'created':
    case 'ACTIVE':
      statusColor = 'blue'
      status = props.status
      break

    case 'paid':
    case 'partially_paid':
    case 'PAID':
      statusColor = 'green'
      status = props.status
      break

    case 'PENDING':
      statusColor = 'orange'
      status = props.status
      break

    case 'CANCELLED':
    case 'EXPIRED':
    case 'expired':
    case 'cancelled':
    case 'failed':
    case 'attempted':
    case 'FAILED':
      statusColor = 'red'
      status = props.status
      break
  }

  return (
    <>
      {status && statusColor && <Status color={statusColor} status={status} />}{' '}
    </>
  )
}

function validatePaymentSplitTotal(
  payment_splits: ServicePaymentSplit[] | undefined,
  amount: number,
): boolean {
  if (!payment_splits || payment_splits.length === 0) {
    return false
  }

  const totalPaymentSplit = payment_splits.reduce(
    (sum: number, split) => sum + split.split_amount,
    0,
  )
  return totalPaymentSplit === amount
}

const EditPaymentForm: React.FC<{
  payment: IPaymentDetailed
  isEditing: boolean
  setIsEditing: (a: boolean) => void
  refetchPayments: CallableFunction
  paymentsRefetch: CallableFunction
}> = ({
  payment,
  refetchPayments,
  paymentsRefetch,
  setIsEditing,
  isEditing,
}) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if (payment) {
      form.setFieldsValue({
        ...payment,
        type: payment?.type?.id,
        service_payment_splits: payment?.service_payment_splits?.map(
          (split) => ({
            id: split.id,
            service: split.service.id,
            split_amount: split.split_amount,
          }),
        ),
      })
    }
  }, [payment, form])

  const { selectProps } = useSelect<IEvent>({
    resource: PROJECT_URL,
    optionLabel: 'name',
    optionValue: 'id',
    onSearch: (projectId: any) => [
      {
        field: 'id',
        operator: 'eq',
        value: Number(projectId),
      },
    ],
    defaultValue: payment?.event,
  })

  const { mutate: updatePayment, isLoading: saving } = useUpdate()

  const handleSave = (values: any) => {
    updatePayment(
      {
        resource: PAYMENT_URL,
        id: payment.id,
        values,
      },
      {
        onSuccess: () => {
          setIsEditing(false)
          refetchPayments()
          paymentsRefetch()
        },
      },
    )
  }

  return (
    <>
      <Spin spinning={false}>
        <Form
          form={form}
          size="small"
          layout="horizontal"
          preserve={false}
          onFinish={handleSave}>
          <Space
            direction="horizontal"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}>
            <Form.Item name="event" label="Project ID">
              <Select {...selectProps} popupMatchSelectWidth={false} />
            </Form.Item>
            <Form.Item name="service" label="Service">
              <SelectWithDefault
                useSelectProps={{
                  resource: SERVICE_URL,
                  optionLabel: 'name',
                  optionValue: 'id',
                }}
                popupMatchSelectWidth={false}
              />
            </Form.Item>
            <Form.Item name="type" label="Payment Type">
              <SelectWithDefault
                useSelectProps={{
                  resource: 'package_tool/payment_types',
                  optionValue: 'id',
                  optionLabel: 'name',
                  pagination: {
                    mode: 'server',
                  },
                }}
                popupMatchSelectWidth={false}
              />
            </Form.Item>
          </Space>

          <Space
            direction="horizontal"
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}>
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                { required: true, message: 'Amount is required' },
                {
                  validator: (_, value) => {
                    const paymentSplits = form.getFieldValue(
                      'service_payment_splits',
                    )
                    if (validatePaymentSplitTotal(paymentSplits, value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(
                        'Total split amount must match the main amount',
                      ),
                    )
                  },
                },
              ]}>
              <InputNumber />
            </Form.Item>

            <Form.Item
              name="transaction_date_time"
              label="Transaction Date Time">
              <DatePicker
                size="small"
                showTime={{ use12Hours: true }}
                format="YYYY-MM-DD hh:mm a"
              />
            </Form.Item>
          </Space>

          <Space>
            <Form.List name="service_payment_splits">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space
                      key={payment.service_payment_splits[index]?.id || key}
                      align="baseline">
                      <Form.Item
                        {...restField}
                        name={[name, 'id']}
                        initialValue={payment.service_payment_splits[index]?.id}
                        style={{ display: 'none' }}>
                        <Input type="hidden" />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'service']}
                        initialValue={
                          payment.service_payment_splits[index]?.service?.id
                        }
                        rules={[
                          { required: true, message: 'Service is required' },
                        ]}>
                        <SelectWithDefault
                          popupMatchSelectWidth={false}
                          useSelectProps={{
                            resource: SERVICE_URL,
                            optionLabel: 'name',
                            optionValue: 'id',
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'split_amount']}
                        initialValue={
                          payment.service_payment_splits[index]?.split_amount
                        }
                        rules={[
                          {
                            required: true,
                            message: 'Split amount is required',
                          },
                          {
                            validator: () => {
                              const mainAmount = form.getFieldValue('amount')
                              const paymentSplits = form.getFieldValue(
                                'service_payment_splits',
                              )
                              if (
                                validatePaymentSplitTotal(
                                  paymentSplits,
                                  mainAmount,
                                )
                              ) {
                                return Promise.resolve()
                              }
                              return Promise.reject(
                                new Error(
                                  'Total split amount must match the main amount',
                                ),
                              )
                            },
                          },
                        ]}>
                        <InputNumber />
                      </Form.Item>

                      <Icons.MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<Icons.PlusOutlined />}>
                      Add Service Payment Split
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Space>

          <Space
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <Button
              size="small"
              type="primary"
              htmlType="submit"
              icon={<Icons.SaveOutlined />}>
              Save
            </Button>
          </Space>
        </Form>
      </Spin>
    </>
  )
}

export const TimelinePaymentCard: React.FC<{
  payment: IPaymentDetailed
  refetchPayments: CallableFunction
  paymentsRefetch: CallableFunction
}> = ({ payment, refetchPayments, paymentsRefetch }) => {
  const { refetch: reCheckPayment } = useOne<IPayment>({
    resource: 'package_tool/payments',
    id: `${payment.id}/refetch`,
    queryOptions: {
      enabled: false,
      onSuccess: () => refetchPayments(),
    },
  })
  const [isEditing, setIsEditing] = useState<boolean>(false)

  const { data: updatePaymentPerm } = useCan({
    action: '',
    resource: 'change_payment',
  })

  const { data: deletePaymentPerm } = useCan({
    action: '',
    resource: 'delete_payment',
  })

  const { mutate: deletePayment } = useDelete()

  return (
    <Card
      size="small"
      actions={
        !isEditing
          ? !!payment.link_url || !!payment.order_id
            ? [
                <Typography.Text
                  key="link_url"
                  copyable={{
                    text:
                      payment.link_url ||
                      `${process.env.REACT_APP_PAYMENT_WEB_APP_URL}/${payment?.id}`,
                  }}
                />,
                <Icons.ReloadOutlined
                  key="refresh"
                  onClick={() => {
                    reCheckPayment()
                  }}
                />,
                <Icons.InfoCircleOutlined
                  key="info"
                  onClick={() => {
                    Modal.info({
                      title: 'Notes',
                      content: payment.note,
                      footer: null,
                      maskClosable: true,
                      centered: true,
                    })
                  }}
                />,
                <Button
                  size="small"
                  key="edit-payment"
                  disabled={!updatePaymentPerm?.can}
                  icon={<Icons.EditOutlined />}
                  onClick={() => setIsEditing(!isEditing)}
                />,
                <Button
                  size="small"
                  key="delete-payment"
                  icon={<Icons.DeleteOutlined />}
                  disabled={!deletePaymentPerm?.can}
                  onClick={() =>
                    deletePayment(
                      {
                        resource: PAYMENT_URL,
                        id: payment?.id,
                      },
                      {
                        onSuccess: () => {
                          paymentsRefetch()
                          refetchPayments()
                        },
                      },
                    )
                  }
                />,
              ]
            : [
                <Button
                  size="small"
                  key="edit-payment"
                  disabled={!updatePaymentPerm?.can}
                  icon={<Icons.EditOutlined />}
                  onClick={() => setIsEditing(!isEditing)}
                />,
                <Button
                  size="small"
                  key="delete-payment"
                  icon={<Icons.DeleteOutlined />}
                  disabled={!deletePaymentPerm?.can}
                  onClick={() =>
                    deletePayment(
                      {
                        resource: PAYMENT_URL,
                        id: payment?.id,
                      },
                      {
                        onSuccess: () => {
                          paymentsRefetch()
                          refetchPayments()
                        },
                      },
                    )
                  }
                />,
              ]
          : [
              <Icons.CloseOutlined
                key="cancel-payment-edit"
                onClick={() => setIsEditing(!isEditing)}
              />,
            ]
      }>
      <Card.Meta
        title={
          !isEditing && (
            <Typography.Text>
              {payment.type?.name} ({<PriceField value={payment?.amount} />})
              {payment.is_custom_amount && <sup>Custom</sup>}
              {payment.is_external && <sup>External</sup>}
              {payment.note && (
                <Tooltip
                  title={
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {payment.note}
                    </span>
                  }>
                  <Icons.InfoCircleOutlined
                    style={{ marginLeft: 4, color: '#1890ff' }}
                  />
                </Tooltip>
              )}
            </Typography.Text>
          )
        }
        description={
          <>
            {!isEditing && (
              <div>
                {payment.service_payment_splits.map((item) => {
                  return (
                    <Descriptions
                      column={2}
                      key={(item.service as Service)?.id}>
                      <Descriptions.Item label={'Service'}>
                        <Typography.Text>
                          {(item.service as Service)?.name ?? ''}
                        </Typography.Text>
                      </Descriptions.Item>

                      <Descriptions.Item label={'Split Amount'}>
                        <PriceField value={item.split_amount} />
                      </Descriptions.Item>
                    </Descriptions>
                  )
                })}

                <Descriptions column={2}>
                  <Descriptions.Item label={'Status'}>
                    <PaymentStatus status={payment.status} />
                  </Descriptions.Item>

                  <Descriptions.Item label={'Method'}>
                    <Typography.Text>{payment.method ?? 'N/A'}</Typography.Text>
                  </Descriptions.Item>

                  {payment?.raw_response?.message && (
                    <Descriptions.Item label={'Raw JSON'}>
                      <ReactJson
                        collapsed
                        src={payment?.raw_response}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        name="error"
                        quotesOnKeys={false}
                      />
                    </Descriptions.Item>
                  )}
                </Descriptions>
              </div>
            )}
          </>
        }
      />
      {isEditing && (
        <EditPaymentForm
          payment={payment}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          refetchPayments={refetchPayments}
          paymentsRefetch={paymentsRefetch}
        />
      )}
    </Card>
  )
}
