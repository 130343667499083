import { Edit, ListButton, RefreshButton, SaveButton } from '@refinedev/antd'
import * as Icons from '@ant-design/icons'
import { Button, Card, Modal, Spin, Typography } from 'antd'
import { useCan, useCreate, useModal, useRouterContext } from '@refinedev/core'
import { useForm } from 'components/hooks/useForm'
import parse from 'html-react-parser'
import { DeliverableForm } from './Form'
import { IDeliverable } from 'interfaces/product'
import { useState } from 'react'
import { DELIVERABLES_URL } from 'urls'
import { useNavigation } from '@refinedev/core'

export const DeliverableEdit: React.FC = () => {
  const { list } = useNavigation()
  const useFormProps = useForm<IDeliverable>({
    resource: DELIVERABLES_URL,
    redirect: false,
  })

  const { mutate: postDeliverable } = useCreate()
  const { useParams } = useRouterContext()
  const { id } = useParams<any>()

  const { visible, show, close } = useModal()

  const isDeleted = useFormProps.queryResult?.data?.data.is_deleted

  const { data: updateDeliverablePerm } = useCan({
    resource: 'change_deliverables',
    action: '',
  })

  const { data: deleteDeliverablePerm } = useCan({
    resource: 'delete_deliverables',
    action: '',
  })

  return (
    <Edit
      footerButtons={() => (
        <>
          {deleteDeliverablePerm?.can && isDeleted ? (
            <Button onClick={show} icon={<Icons.RedoOutlined />} type="primary">
              Restore
            </Button>
          ) : (
            <Button onClick={show} danger icon={<Icons.DeleteOutlined />}>
              Delete
            </Button>
          )}
        </>
      )}
      headerProps={{
        ghost: false,
        title: 'Edit Deliverable',
        extra: [
          <Button
            key="list"
            icon={<Icons.UnorderedListOutlined />}
            onClick={() => list(DELIVERABLES_URL)}>
            Deliverables List
          </Button>,
          <RefreshButton key="refresh" />,
        ],
      }}>
      {useFormProps.formLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Spin />
        </div>
      ) : (
        <>
          {isDeleted && (
            <Card
              title={<Typography.Text type="danger">Deleted</Typography.Text>}
              style={{ borderColor: 'red' }}
              headStyle={{ borderColor: 'red' }}></Card>
          )}
          <DeliverableForm
            useFormProps={useFormProps}
            disabled={isDeleted || !updateDeliverablePerm?.can}
          />
          <Modal
            visible={visible}
            title={isDeleted ? 'Restore' : 'Delete'}
            onCancel={close}
            onOk={() => {
              postDeliverable(
                {
                  resource: `${DELIVERABLES_URL}/${id}/${
                    isDeleted ? 'restore' : 'delete'
                  }`,
                  values: {},
                },
                { onSuccess: () => useFormProps.queryResult?.refetch() },
              )
              close()
            }}
            okText={isDeleted ? 'Restore' : 'Delete'}
          />
        </>
      )}
    </Edit>
  )
}
