import React, { useState } from 'react'
import { Button, Table, Typography } from 'antd'
import { List, useTable, useModal } from '@refinedev/antd'
import {
  useApiUrl,
  useCan,
  useCustomMutation,
  useRouterContext,
} from '@refinedev/core'
import { DELIVERABLES_URL } from 'urls'

export const DeliverableList: React.FC = () => {
  const { Link } = useRouterContext()
  const { tableProps, tableQueryResult } = useTable({
    resource: DELIVERABLES_URL,
    meta: {
      populate: [
        'id',
        'name',
        'description',
        'default_qty',
        'uom',
        'is_deleted',
      ],
    },
  })
  const apiUrl = useApiUrl()
  const { mutate } = useCustomMutation()

  const { data: addDeliverablePerm } = useCan({
    resource: 'add_deliverables',
    action: '',
  })

  const { data: deleteDeliverablePerm } = useCan({
    resource: 'delete_deliverables',
    action: '',
  })

  return (
    <List
      title="Deliverables"
      headerButtons={({ defaultButtons }) => (
        <>{addDeliverablePerm && defaultButtons}</>
      )}>
      <Table
        {...tableProps}
        rowKey="id"
        rowClassName={(record) => (record.is_deleted ? 'disabled-row' : '')}>
        <Table.Column
          dataIndex="name"
          title="Name"
          render={(value, record: any) => (
            <>
              {record.is_deleted && (
                <Typography.Text type="danger">(Deleted)</Typography.Text>
              )}{' '}
              <Link to={`edit/${record.id}`}>{value}</Link>
            </>
          )}
        />
        <Table.Column dataIndex="description" title="Description" />
        <Table.Column dataIndex="default_qty" title="Default Quantity" />
        <Table.Column dataIndex="uom" title="Unit of Measurement" />
        <Table.Column
          title="Actions"
          render={(record: any) => (
            <>
              {record.is_deleted ? (
                <Button
                  disabled={!deleteDeliverablePerm?.can}
                  onClick={() =>
                    mutate(
                      {
                        url: `${apiUrl}/${DELIVERABLES_URL}/${record.id}/restore/`,
                        method: 'post',
                        values: {},
                      },
                      {
                        onSuccess: (data) => {
                          tableQueryResult.refetch()
                        },
                      },
                    )
                  }>
                  Restore
                </Button>
              ) : (
                <Button
                  disabled={!deleteDeliverablePerm?.can}
                  danger
                  onClick={() =>
                    mutate(
                      {
                        url: `${apiUrl}/${DELIVERABLES_URL}/${record.id}/delete/`,
                        method: 'post',
                        values: {},
                      },
                      {
                        onSuccess: (data) => {
                          tableQueryResult.refetch()
                        },
                      },
                    )
                  }>
                  Delete
                </Button>
              )}
            </>
          )}
        />
      </Table>
    </List>
  )
}
