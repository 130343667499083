import React from 'react'
import { Form, Input, InputNumber, Modal, Button } from 'antd'
import {
  useCreate,
  UseFormReturnType,
  useNavigation,
  useUpdate,
} from '@refinedev/core'
import { IDeliverable } from 'interfaces/product'
import { DELIVERABLES_URL } from 'urls'

export const DeliverableForm: React.FC<{
  useFormProps: UseFormReturnType<IDeliverable>
  disabled?: boolean
}> = ({ useFormProps, disabled }) => {
  const [form] = Form.useForm()
  const { mutate: create } = useCreate()
  const { mutate: update } = useUpdate()
  const { list } = useNavigation()

  const handleSubmit = (values: any) => {
    if (values.id) {
      update(
        {
          resource: DELIVERABLES_URL,
          id: values.id,
          values,
        },
        {
          onSuccess: () => {
            list(DELIVERABLES_URL)
          },
        },
      )
    } else {
      create(
        {
          resource: DELIVERABLES_URL,
          values,
        },
        {
          onSuccess: () => {
            list(DELIVERABLES_URL)
          },
        },
      )
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      initialValues={useFormProps.queryResult?.data?.data}
      disabled={disabled}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name="name"
        label="Name"
        rules={[{ required: true, message: 'Name is required' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
        rules={[{ required: true, message: 'Description is required' }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="default_qty"
        label="Default Quantity"
        rules={[{ required: true, message: 'Quantity is required' }]}>
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item name="uom" label="Unit of Measurement">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}
